<template>
  <v-container id="resume" fluid class="pb-15">
    <div class="col-md-12 mx-auto">
      <div class="col-md-12">
        <h1 class="section-title" style="color: black">
          <span style="text-align: center">
            {{ $t('default.experience.title') }}
          </span>
        </h1>
      </div>
    </div>
    <v-container>
      <div class="vertical-timeline vertical-timeline--animate vertical-timeline--two-columns">
        <div
          class="vertical-timeline-element--work vertical-timeline-element"
          v-for="(item, index) in $t('default.experience.resume')"
          :key="index"
        >
          <span
            class="vertical-timeline-element-icon bounce-in"
            style="background: rgb(174, 148, 79); color: rgb(255, 255, 255); text-align: center"
            v-if="isVisible"
          >
            <v-icon class="experience-icon">{{ item.icon }}</v-icon>
          </span>
          <div class="vertical-timeline-element-content bounce-in" v-if="isVisible">
            <div class="vertical-timeline-element-content-arrow"></div>
            <div style="text-align: left; margin-bottom: 4px">
              <span class="main-badge mr-2 mb-2 badge badge-pill">
                {{ item.badge }}
              </span>
            </div>
            <h3
              class="vertical-timeline-element-title"
              style="text-align: left; font-size: 1.75rem; font-weight: 500; line-heigth: 1.2"
            >
              {{ item.title }}
            </h3>
            <div
              class="vertical-timeline-element-subtitle"
              style="text-align: left; margin-top: 15px; font-size: 1.5rem; font-weight: 500; line-heigth: 1.2"
              v-html="item.subtitle"
            ></div>
            <div style="text-align: left; margin-top: 15px">
              <!-- <span class="experience-badge mr-2 mb-2 badge badge-pill" v-for="pill in item.pills" :key="pill">{{ pill }}</span> -->
              <v-chip class="experience-badge mr-2 mb-2 badge badge-pill" v-for="pill in item.pills" :key="pill" small>
                {{ pill }}
              </v-chip>
            </div>
            <span class="vertical-timeline-element-date">
              {{ item.date }}
            </span>
          </div>
        </div>

        <div class="vertical-timeline-element--work vertical-timeline-element">
          <span
            class="vertical-timeline-element-icon bounce-in"
            style="background: rgb(174, 148, 79); color: rgb(255, 255, 255); text-align: center"
            v-if="isVisible"
          >
            <v-icon class="experience-icon">mdi-timer-sand</v-icon>
          </span>
        </div>
      </div>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: 'FooterPage',
  components: {},
  data() {
    return {
      show: true,
      isVisible: true,
      items: [
        {
          color: 'red lighten-2',
          icon: 'mdi-star'
        },
        {
          color: 'purple darken-1',
          icon: 'mdi-book-variant'
        },
        {
          color: 'green lighten-1',
          icon: 'mdi-airballoon'
        },
        {
          color: 'indigo',
          icon: 'mdi-buffer'
        }
      ]
    };
  },
  methods: {
    visibilityChanged(isVisible) {
      this.isVisible = isVisible;
    },
    handleScroll() {
      this.$el.querySelector('vertical-timeline-element-content')?.value;
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  }
};
</script>

<style lang="css" scoped>
.no-decor {
  color: inherit;
}
#skills {
  background: #fff;
  overflow: hidden;
  font-size: 200%;
}
#skills h1 {
  padding-top: 5%;
  font: 18px/24px 'opensans-bold', sans-serif;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: black;
  text-align: center;
}
.child {
  margin: auto;
  font-size: 1.5rem;
}

.experience-badge {
  color: black;
}

.badge {
  border-radius: 10rem;
  margin: 0 0.3rem;
}

.experience-icon {
  font-size: 300%;
  margin-top: 25%;
  text-align: center;
  color: white;
}
@media only screen and (max-width: 1170px) {
  .experience-icon {
    font-size: 175%;
    margin-top: 27%;
    text-align: center;
  }
}
</style>
