<template lang="html">
  <v-container class="text-center mx-auto w-50 d-flex" style="flex-direction: column">
    <div class="skill-category-header">{{ title }}</div>
    <v-row justify="center" class="skill-icon">
      <template v-for="(skill, index) in skillList">
        <v-col
          style="flex: 0 1 auto; width: auto"
          :key="index"
          class="icon-item"
          @mouseover="hoverIn(skill.text)"
          @mouseleave="hoverOut()"
        >
          <span>
            <div class="text-center skills-tile">
              <template v-if="skill.icon">
                <fa-icon :icon="skill.icon" style="font-size: 6rem" />
              </template>
              <template v-else>
                <svg
                  role="img"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="icon"
                  data-icon="custom"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  class="svg-inline--fa fa-react"
                  style="font-size: 6rem"
                >
                  <title>Cypress</title>
                  <path fill="currentColor" :d="skill.fallback" />
                </svg>
              </template>

              <p class="text-center" style="font-size: 10px; margin-top: 4px">
                {{ skill.text }}
              </p>
              <div class="progress bg-dark" style="width: 100%; height: 4px">
                <v-progress-linear
                  :value="skill.text === selectedSkillIcon ? skill.level : 0"
                  :style="[skill.text === selectedSkillIcon ? { width: '100%' } : { width: '0px' }]"
                >
                </v-progress-linear>
              </div>
            </div>
          </span>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'SkillsBoxes',
  props: {
    title: String,
    skillList: Array
  },
  data() {
    return {
      selectedSkillIcon: null,
      hover: false
    };
  },
  components: {},
  methods: {
    hoverIn(icon) {
      this.selectedSkillIcon = icon;
      this.hover = true;
    },
    hoverOut() {
      this.selectedSkillIcon = null;
      this.hover = false;
    }
  }
};
</script>

<style lang="css" scoped>
.icon-item {
  transition: 0.1s;
}
.icon-item:hover {
  transform: scale(1.2);
}
.list-inline {
  padding-left: 0;
  list-style: none;
  display: flex;
}
.skill-category-header {
  font-size: 1.5rem;
  font-weight: 600;
  text-align: left;
  color: #ccc;
}

.skills-tile {
  background-color: rgba(248, 245, 244, 0.05);
  padding: 10px 10px 5px 10px;
  width: 100px;
  margin: 5px 0 5px 0;
  border-radius: 8px;
}

.skill-icon {
  font-size: 180%;
  color: white;
  text-align: center;
  position: relative;
}
</style>
