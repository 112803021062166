<template>
  <v-app>
    <LandingPage></LandingPage>
    <AboutMe></AboutMe>
    <MySkills></MySkills>
    <Experience v-on:scroll.native="console.log('Hello')"></Experience>
    <Footer></Footer>
  </v-app>
</template>

<script>
import LandingPage from './components/_pages/LandingPage';
import AboutMe from './components/_pages/AboutMe';
import MySkills from './components/_pages/Skills';
import Experience from './components/_pages/Experience';
import Footer from './components/_pages/Footer';
export default {
  name: 'CodingPete',
  components: {
    LandingPage,
    AboutMe,
    MySkills,
    Experience,
    Footer
  },
  data: () => ({})
};
</script>

<style lang="scss">
@import './App.scss';

#app {
  background-color: #202020;
  color: #707070;
  min-height: 100vh;
}

.flag {
  border-radius: 20%;
  margin: 10px !important;
}

.active {
  filter: brightness(40%);
  transition: 0.2s;
}
</style>
