import Vue from 'vue';
import VueI18n from 'vue-i18n';

// language files
import * as en from './en.json';
import * as cs from './cs.json';

Vue.use(VueI18n);

// load language files
const messages = {
  en: { ...en },
  cs: { ...cs }
};

export default new VueI18n({
  locale: 'en', //default lang
  messages
});
