<template>
  <v-container class="mx-auto text-center language">
    <template v-for="(lang, index) in langs">
      <div style="display: inline" :key="index">
        <span class="language-icon mr-5" @click="selectedLanguage(lang.code)">
          <country-flag :country="lang.code" size="big" :class="[lang.code === selectedLang ? 'active' : '']" />
        </span>
      </div>
    </template>
  </v-container>
</template>

<script>
import CountryFlag from 'vue-country-flag';

export default {
  name: 'LanguageSwitch',
  components: { CountryFlag },
  data: () => ({
    selectedLang: '',
    langs: [
      { code: 'us', lang: 'English', country: 'United States', locale: 'en' },
      { code: 'cz', lang: 'Česky', country: 'Česká Republika', locale: 'cs' }
    ]
  }),
  methods: {
    selectedLanguage(lang) {
      this.selectedLang = lang;
      this.$i18n.locale = this.langs.filter(item => item.code == lang)[0].locale;
    }
  },
  created() {
    this.selectedLang = this.langs.filter(item => item.locale == this.$i18n.locale)[0].code;
  }
};
</script>

<style lang="scss">
.language {
  font-size: 25px;
  background-color: #e9d5a1;
  padding-bottom: 10px;
  padding-top: 80px;
}

.language-icon {
  font-size: 50px;
  cursor: pointer;
}

.flag {
  border-radius: 20%;
  margin: 10px !important;
}

.active {
  filter: brightness(40%);
  transition: 0.2s;
}
</style>
