<template>
  <v-container id="about" fluid>
    <v-row>
      <v-col>
        <h1>
          {{ $t('default.about.title') }}
        </h1>
      </v-col>
    </v-row>
    <v-row justify="center" align="center">
      <v-col class="text-center">
        <v-container class="polaroid">
          <div style="cursor: auto" class="frame">
            <img src="./../../../public/images/codingpete.png" alt="Coding Pete" />
            <v-row class="mt-4">
              <v-col><v-icon x-large color="rgb(65, 184, 131)">mdi-vuejs</v-icon></v-col>
              <v-col><v-icon x-large color="black">mdi-nodejs</v-icon></v-col>
              <v-col><v-icon x-large color="rgb(221, 72, 20)">mdi-ubuntu</v-icon></v-col>
            </v-row>
          </div>
        </v-container>
      </v-col>
      <v-col>
        <v-card style="width: 80%" class="card">
          <v-card-title class="card-header">
            <v-row>
              <v-col> pete@codingpete:~$<span class="font-weight-light"> whoami</span> </v-col>
              <v-col class="text-right">
                <v-icon small>mdi-window-minimize</v-icon>
                <v-icon class="mx-2" small>mdi-window-maximize</v-icon>
                <v-icon small>mdi-window-close</v-icon>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text class="card-body">
            <div style="height: auto; font-size: 180%; line-height: 200%" class="text-justify">
              <div class="wave my-5">{{ $t('default.about.greeting') }} :)</div>
              <p>
                <v-icon color="rgb(254, 208, 172)" x-large>mdi-hand-peace-variant</v-icon>
                {{ $t('default.about.text', { years: getYearsOfExperience() }) }}
                <a href="https://www.linkedin.com/in/petrhybler/" target="_blank">LinkedIn</a>
                {{ $t('default.about.text2') }}
                <br />{{ $t('default.about.farewell') }}
              </p>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'AboutMe',
  data: () => ({
    yearWorkStart: 2010
  }),
  methods: {
    getYearsOfExperience() {
      const date = new Date();
      return date.getFullYear() - this.yearWorkStart;
    }
  },
  components: {}
};
</script>

<style lang="scss" scoped>
.wave {
  font-size: 160%;
}
#about {
  background: #fff;
  overflow: hidden;
  font-size: 200%;
}

#about h1 {
  padding-top: 5%;
  font: 18px/24px 'opensans-bold', sans-serif;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: black;
  text-align: center;
}
.polaroid img {
  display: block;
  max-width: 450px;
}

.polaroid .frame {
  background: #ffffff;
  display: inline-block;
  margin: 55px 75px 30px;
  padding: 15px 15px 30px;
  text-align: center;
  text-decoration: none;
  -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  transition: all 0.2s linear;
  z-index: 0;
  position: relative;
}

.card-header {
  background: #e4e3e3;
}
</style>
