import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
Vue.component('fa-icon', FontAwesomeIcon);

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faHtml5,
  faCss3,
  faVuejs,
  faReact,
  faSass,
  faBootstrap,
  faFigma,
  faNodeJs,
  faJs,
  faPhp,
  faPython,
  faLinux,
  faAws,
  faGitlab,
  faDocker,
  faGithub,
  faLinkedin
} from '@fortawesome/free-brands-svg-icons';
import { faAt, faFileShield, faDesktop } from '@fortawesome/free-solid-svg-icons';
library.add(
  faHtml5,
  faCss3,
  faVuejs,
  faReact,
  faSass,
  faBootstrap,
  faFigma,
  faNodeJs,
  faJs,
  faPhp,
  faPython,
  faLinux,
  faAws,
  faGitlab,
  faDocker,
  faGithub,
  faLinkedin,
  faAt,
  faFileShield,
  faDesktop
);

export default library;
