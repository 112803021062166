import Vue from 'vue';
import App from './App.vue';
import i18n from './lang/index';
import vuetify from './plugins/vuetify';
import faIcons from './plugins/fa-icons';

Vue.config.productionTip = false;

new Vue({
  vuetify,
  faIcons,
  i18n,
  render: h => h(App)
}).$mount('#app');
