<template>
  <v-container id="home" fluid class="text-center header d-flex flex-column" :style="{ height: `${wHeight}px` }" full-height>
    <GitCat></GitCat>
    <v-spacer></v-spacer>
    <v-container class="aligner">
      <v-container full-height style="transform: scale(0.9)">
        <img
          src="./../../../public/assets/laptop-code.png"
          alt=""
          width="130px"
          class="mb-4"
          :class="{ inverted: theme === 'dark' }"
        />
        <br />
        <h1 class="my-2">
          {{ $t('default.header.name') }}
        </h1>
        <div>
          <p class="text-uppercase">
            <span class="title-styles typed-text">{{ typeValue }}</span>
            <span class="cursor" :class="{ typing: typeStatus }">&nbsp;</span>
          </p>
        </div>

        <input type="checkbox" id="toggle" class="toggle--checkbox pointer" @change="switchTheme()" />
        <label for="toggle" class="toggle--label mx-auto half-sized pointer">
          <span class="toggle--label-background pointer"></span>
        </label>
      </v-container>
    </v-container>
    <v-spacer></v-spacer>
    <LanguageSwitch></LanguageSwitch>
  </v-container>
</template>

<script>
import GitCat from './../gitCat';
import LanguageSwitch from './../languageSwitch';
export default {
  name: 'LandingPage',
  components: { GitCat, LanguageSwitch },
  data: () => ({
    wHeight: window.innerHeight,
    typeValue: '',
    typeStatus: false,
    typeArray: [
      'Chief Technical Officer',
      'Full stack developer',
      'Solution Architect',
      'Cyber security enthusiast',
      'JS fan',
      'Sr. Data Engineer'
    ],
    typingSpeed: 50, // lower = faster
    erasingSpeed: 50, // lower = faster
    newTextDelay: 2000,
    typeArrayIndex: 0,
    charIndex: 0,
    theme: 'light'
  }),
  methods: {
    switchTheme() {
      const dataThemeAttribute = 'data-theme';
      const body = document.body;
      const newTheme = body.getAttribute(dataThemeAttribute) === 'dark' ? 'light' : 'dark';
      body.setAttribute(dataThemeAttribute, newTheme);
      this.theme = newTheme;
    },
    typeText() {
      if (this.charIndex < this.typeArray[this.typeArrayIndex].length) {
        if (!this.typeStatus) this.typeStatus = true;
        this.typeValue += this.typeArray[this.typeArrayIndex].charAt(this.charIndex);
        this.charIndex += 1;
        setTimeout(this.typeText, this.typingSpeed);
      } else {
        this.typeStatus = false;
        setTimeout(this.eraseText, this.newTextDelay);
      }
    },
    eraseText() {
      if (this.charIndex > 0) {
        if (!this.typeStatus) this.typeStatus = true;
        this.typeValue = this.typeArray[this.typeArrayIndex].substring(0, this.charIndex - 1);
        this.charIndex -= 1;
        setTimeout(this.eraseText, this.erasingSpeed);
      } else {
        this.typeStatus = false;
        this.typeArrayIndex += 1;
        if (this.typeArrayIndex >= this.typeArray.length) this.typeArrayIndex = 0;
        setTimeout(this.typeText, this.typingSpeed + 1000);
      }
    }
  },
  created() {
    setTimeout(this.typeText, this.newTextDelay + 200);
  }
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 300%;
  font-weight: 600 !important;
  color: #353239;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  margin: 0 auto 18px auto;
  width: 100%;
  span.typed-text {
    color: #d2b94b;
  }
  span.cursor {
    display: inline-block;
    margin-left: 3px;
    width: 4px;
    background-color: #fff;
    animation: cursorBlink 1s infinite;
  }
  span.cursor.typing {
    animation: none;
  }
}
.header {
  position: relative;
  height: 520px;
  min-height: 450px;
  width: 100%;
  background-size: cover;
  -webkit-background-size: cover;
  text-align: center;
  overflow: hidden;
  background-color: #e9d5a1;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  margin: 0;
  padding-top: 150px;
  padding-bottom: 0;
}
@keyframes cursorBlink {
  49% {
    background-color: #fff;
  }
  50% {
    background-color: transparent;
  }
  99% {
    background-color: transparent;
  }
}
.half-sized {
  transform: scale(0.5);
}
.inverted {
  filter: invert(0.6);
}
.pointer {
  cursor: pointer;
}
</style>
