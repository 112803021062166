<template>
  <footer>
    <div class="col-md-12">
      <div class="social-links">
        <template v-for="(network, index) in networks">
          <span :key="index" class="mx-2">
            <a :href="network.url" target="_blank" rel="noopener noreferrer" class="no-decor">
              <fa-icon :icon="network.icon" style="font-size: 3rem" />
            </a>
          </span>
        </template>
      </div>
      <div class="copyright py-4 text-center">
        <div class="container">
          <small> Copyright © www.codingpete.com </small>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterPage',
  components: {},
  data() {
    return {
      networks: [
        { name: 'GitHub', url: 'https://github.com/petr-hybler', icon: 'fa-brands fa-github' },
        { name: 'LinkedIn', url: 'https://www.linkedin.com/in/petrhybler/', icon: 'fa-brands fa-linkedin' },
        { name: 'Email', url: 'mailto:petr.hybler@gmail.com?Subject=Message from codingpete.com', icon: 'fa-solid fa-at' }
      ]
    };
  }
};
</script>

<style lang="css" scoped>
.no-decor {
  color: inherit;
}
</style>
